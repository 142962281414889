<template>
  <div class="edit">
    <page-nav title="编辑资料" />
      <van-form @submit="onSubmit" validate-trigger="onSubmit">
        <div class="cell-group">
          <van-field
            v-model="nickName"
            name="nickName"
            label="昵称"
            placeholder="请输入昵称"
            input-align="right"
            maxlength="10"
          />
          <van-field name="headImg" label="头像" input-align="right">
            <template #input>
              <van-uploader v-model="uploadList" :after-read="afterRead" :max-count="1" :max-size="5 * 1024 * 1024" />
            </template>
          </van-field>
          <van-field
            v-model="email"
            name="email"
            label="电子邮箱"
            placeholder="请输入"
            input-align="right"
          />
        </div>
        <div class="cell-group">
          <div class="action">
            <van-button block round type="info" native-type="submit">保存</van-button>
          </div>
        </div>
      </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { HOST } from '@/common/const';
import User from '@/common/user';
import { Toast, Form, Field, Uploader } from 'vant';
import Fetch from '@/common/fetch';
import { UploadAvatar, EditUserInfo, GetUserInfo } from '@/common/interface';
import PageNav from '@/components/PageNav.vue';
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Uploader);

export default {
  name: 'UserEdit',
  components: { PageNav },
  data() {
    return {
      userInfo: User.getInfo(),
      nickName: '',
      email: '',
      uploadList: [],
    };
  },
  created() {
    if (this.userInfo) {
      const { nickName, headImg, headKey, email } = this.userInfo;
      this.nickName = nickName;
      this.email = email;
      this.uploadList = [{ url: headImg, uid: headKey, name: 'avatar' }];
    }
  },
  methods: {

    afterRead(file) {
      const header = {
        sourceFlag: 'web',
        channelCode: '10001',
        appOwnership: 'EBei',
      };

      const user = User.getInfo() || {};
      const { token, uuid } = user;
      if (token) {
        header.token = token;
      }
      if (uuid) {
        header.uuid = uuid;
      }

      const imageUploadAction = `${HOST}${UploadAvatar}`;

      const filedata = new FormData();
      filedata.append('file', file.file);

      axios
      .post(imageUploadAction, filedata, {
        headers: {
          ...header,
          "Content-Type": "multipart/form-data"
        },
      })
      .then(res => {
        if (res.data.code == 1000) { // 上传成功
          const data = res.data.data;
          this.uploadList[0].url = data.fileUrl;
          this.uploadList[0].uid = data.fileKey;
        } else {
          Toast('上传失败请重试');
        }
      });
    },

    onSubmit(values) {
      if (this.nickName.length == 0 || this.nickName.length > 10) {
        Toast('昵称长度为1~10位字符');
        return;
      }
      if (this.uploadList.length == 0 || !this.uploadList[0].url) {
        Toast('请上传头像');
        return;
      }
      
      const params = {
        ...values,
        headImg: this.uploadList[0].url || '',
      };
      Fetch.post(EditUserInfo, params).then(res => {
        console.log(res);
        Toast.success('保存成功');
        this.refreshUserInfo();
      }).catch((err) => {
        Toast(err.msg);
      });

    },

    refreshUserInfo() {
      const user = User.getInfo() || {};
      const { uuid } = user;
      const params = {
        uuid,
      };
      Fetch.post(GetUserInfo, params).then((res) => {
        User.modifyInfo(res);
      }).catch(() => {
      });
    }

  },
}
</script>

<style lang="scss" scoped>
.edit {
  .cell-group {
    background: #fff;
    margin: 20px 0;
    .action {
      padding: 0 16px;
    }
  }
}
</style>